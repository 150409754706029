<template>
    <div v-if="$can('correccion.menu.consultar.ajuste')">
        <ConsultaAjusteSalida />
    </div>
</template>
    
<script>
    import ConsultaAjusteSalida from './Modulos/ConsultaAjusteSalida.vue'

    export default {
    
        components: {
            ConsultaAjusteSalida
        },
    
        data () {
            return {
                spinnerloading: false
            }
        }
    }
</script>