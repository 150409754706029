<template>
    <div v-if="$can('correccion.submenu.consultar.ajuste.inventario.entrada')">
        <a-row type="flex" :gutter="[2, 5]">
            <a-divider orientation="left">
				<span style="color:black; font-size:18px;">Ajuste de Salida</span> <span style="color:#9C9C9C; font-size:12px;">Visualiza tus comprobantes de ajustes</span>
			</a-divider>

            <a-col flex="1 1 100px">
                <a-button 
                    block 
                    type="primary" 
                    @click="openMenu('ajusteinventariosalida')" 
                    v-if="$can('correccion.menu.ajuste')"
                    >
                    <a-icon type="plus" /> Nuevo Ajuste de Salida
                </a-button>
            </a-col>
            
            <a-col flex="1 1 100px">
                <a-button block type="dashed" @click="openModalConsultaOpciones()"><a-icon type="search" /> Consultar Opciones</a-button>
            </a-col>

            <a-col flex="1 1 200px">
				<a-date-picker 
                    :value="utils.getDateFormat(consulta.fecha_inicio)" 
                    @change="onChangeFechaInicio" 
                    :locale="locale"
                    placeholder="Fecha Inicio"
                    style="width: 100%"
                />
            </a-col>

            <a-col flex="1 1 200px">
				<a-date-picker 
                    :value="utils.getDateFormat(consulta.fecha_final)" 
                    @change="onChangeFechaFinal" 
                    :locale="locale"
                    placeholder="Fecha Final"
                    style="width: 100%"
                />
			</a-col>

            <a-col flex="1 1 300px">
                <a-input-search placeholder="Buscar por codigo" @search="onSearch">
                    <a-button type="primary" slot="enterButton">
                        Buscar
                    </a-button>
                </a-input-search>
            </a-col>

            <a-col :span="1">
                <a-button @click="refrescar">
                    <a-icon type="reload" />
                </a-button>
            </a-col>
        </a-row>

		<br>

        <a-table 
            :columns="columns" 
            :data-source="ajustes" 
            :pagination="paginate"
            :loading="loading"
            :ellipsis="true"
            size="small"
            :rowKey="record => record.ajuste_id"
            :scroll="{ x: 980 }"
            @change="handlePagination"
            >

                <template slot="codigo" slot-scope="item">
                    <span>{{ item.codigoAjuste }}</span>
                </template>

                <template slot="almacen" slot-scope="item">
                    <span>{{ item.almacen.nombre }}</span>
                </template>

                <template slot="fecharegistro" slot-scope="item">
                    {{ (item.fechaAjuste) ? utils.formatOnlyDate(item.fechaAjuste) : 'No registrado' }} {{ (item.horaAjuste) ? item.horaAjuste : '' }}
                </template>

                <span slot="expandedRowRender" slot-scope="item" style="margin: 0">
                    <span style="font-weight:700; font-size:17px;">DATOS DE REGISTRO</span>
                    <a-row>
                        <a-col :span="4">
                            <a-list size="small" bordered><a-list-item>AJUSTE ID:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>CODIGO:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>SUCURSAL:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>OBSERVACION:</a-list-item></a-list>
                        </a-col>
                        <a-col :span="20">
                            <a-list size="small" bordered><a-list-item>{{ item.ajuste_id }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.codigoAjuste }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.almacen.nombre }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ (item.observacion) ? item.observacion : 'Sin Observacion' }}</a-list-item></a-list>
                        </a-col>
                    </a-row>
                </span>

                <template slot="action" slot-scope="item">
                    <a-dropdown>
                        <a-menu slot="overlay">

                            <a-menu-item :key="buttonKeyVerReporte">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>{{ item.codigoAjuste }}</span>
                                    </template>
                                    <a href="#" @click.prevent="reporteCarta(item.ajuste_id)"><a-icon type="file-pdf" :style="{ fontSize: '18px', color: '#fa1d03' }"/> &nbsp;<strong>Comprobante PDF</strong></a>
                                </a-tooltip>
                            </a-menu-item>

                            <a-menu-item :key="buttonKeyImprimirReporte">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>{{ item.codigoAjuste }}</span>
                                    </template>
                                    <a href="#" @click.prevent="imprimirTermico(item.ajuste_id)"><a-icon type="file-text" :style="{ fontSize: '18px', color: '#02c7c1' }"/> &nbsp;<strong>Comprobante Térmico</strong></a>
                                </a-tooltip>
                            </a-menu-item>

                        </a-menu>
                        <a-button> Acciones <a-icon type="down" /> </a-button>
                    </a-dropdown>
                </template>
        </a-table>

        <!-- MODAL CONSULTAS OPCIONES -->
        <a-modal
            v-model="modalConsultaOpciones"
            title="Consultar Ventas"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="350"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <a-row type="flex">
                    <a-col flex="1 1 200px" v-if="$can('correccion.consultar.ajuste.inventario.entrada.por.sucursal')">
                        <label for="almacen" class="control-label">Sucursal:</label>
                        <a-select size="large" label-in-value :value="{ key: consulta.almacenID }" @change="onChangeAlmacen"  style="width: 100%">
                            <a-select-option v-for="(value, index) in almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" v-if="$can('correccion.consultar.ajuste.inventario.entrada.por.usuario')">
                        <label for="proveedor" class="control-label">Usuarios:</label>
                        <a-select size="large" :value="consulta.usuario" @change="onChangeUsuario"  style="width: 100%">
                            <a-select-option v-for="(value, index) in usuarios" :key="index" :value="value.usuario"> {{ value.usuario }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px">
                        <label class="control-label">Accion:</label>
                        <a-button size="large" type="primary" block @click="refrescar">
                            <a-icon type="reload" /> Reestablecer Opciones Busqueda
                        </a-button>
                    </a-col>
                </a-row>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalConsultaOpciones = false">
                    Cerrar
                </a-button>
            </template>
        </a-modal>

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
    </div>
</template>

<script>
    const columns = [
        { 
            id: 'ajuste_id', 
            title: 'Codigo', 
            dataIndex: '',
            key: 'codigo',
            scopedSlots: { customRender: 'codigo' },
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            width: '15%'
        },
        { 
            id: 'ajuste_id',
            title: 'Sucursal', 
            dataIndex: '',
            key: 'almacen',
            scopedSlots: { customRender: 'almacen' },
            width: '20%',
            ellipsis: true
        },
        { 
            id: 'ajuste_id',
            title: 'Usuario', 
            dataIndex: 'usuario',
            width: '20%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'ajuste_id',
            title: 'Moneda', 
            dataIndex: 'nombreTipoCambio',
            width: '15%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'ajuste_id',
            title: 'Total', 
            dataIndex: 'montoTotal',
            width: '10%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'ajuste_id', 
            title: 'F.Registro', 
            dataIndex: '',
            key: 'fecharegistro',
            scopedSlots: { customRender: 'fecharegistro' },
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            width: '15%'
        },
        { 
            id: 'ajuste_id',
            title: 'Acciones', 
            dataIndex: '',
            scopedSlots: { customRender: 'action' },
            width: '12%'
        }
    ]

	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import * as utils from '@/utils/utils'
    import { mapGetters, mapActions } from 'vuex'
    import router from '@/config/router'

    export default {

        components: {
        },

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
				moment,
				locale,
                columns,
                utils,
                consulta: {
                    almacenID: 'Seleccionar',
					usuario: 'Seleccionar',
                    fecha_inicio: '',
                    fecha_final: '',
                    nombreAlmacen: '',
                    keyword: ''
                },
                loading: false,
                showModal: false,
				spinnerloading: false,
                modalConsultaOpciones: false,
                buttonKeyVerReporte: 100,
                buttonKeyImprimirReporte: 200,
                paginate: {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'ajuste_id',
                    order: 'descend'
                }
            }
        },

        computed: {
			...mapGetters('auth', ['user']),
            ...mapGetters('ajuste', ['ajustes']),
            ...mapGetters('almacen', ['almacenes']),
            ...mapGetters('usuario', ['usuarios'])
		},

        methods: {
            ...mapActions('ajuste', ['getPaginate']),
            ...mapActions('almacen', ['getAlmacenListado']),
			...mapActions('usuario', ['getUsuarioListar']),

            openMenu (name) {
				router.push({ name: name })
			},
            
            filterOption (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            clearData () {
                this.consulta.usuario = 'Seleccionar'
                this.consulta.fecha_inicio = ''
                this.consulta.fecha_final = ''
                this.consulta.keyword = ''
            },

            getAlmacenes () {
                this.getAlmacenListado()
				.then(response => {
					this.consulta.almacenID = this.user.almacenID.toString()
				})
				.catch(error => {
					utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
				})
            },

            openModalConsultaOpciones () {
                this.modalConsultaOpciones = true
            },

            onChangeFechaInicio (date, dateString) {
				this.consulta.fecha_inicio = dateString
			},

            onChangeFechaFinal (date, dateString) {
				this.consulta.fecha_final = dateString
                this.paginarResultados()
			},

            refrescar (value) {
                this.clearData()
                this.paginarResultados()
            },

            onChangeAlmacen (value) {
                this.consulta.almacenID = value.key
                this.consulta.nombreAlmacen = value.label
                this.paginarResultados()
            },
		
			onChangeUsuario (value) {
                this.consulta.usuario = value
                this.paginarResultados()
            },

            onSearch (value) {
                this.consulta.keyword = value
                this.paginarResultados()
            },
            // Pagination
            handlePagination (paginate, filters, sorter) {
                this.paginate = {
                    pageSize: paginate.pageSize,
                    current: paginate.current,
                    total: paginate.total,
                    field: (sorter.field) ? sorter.field : 'ajuste_id',
                    order: (sorter.order) ? sorter.order : 'descend'
                }
                this.paginarResultados()
            },

            changePaginate (data) {
                this.paginate = {
                    pageSize: data.per_page,
                    current: data.current_page,
                    total: data.total,
                    field: 'ajuste_id',
                    order: 'descend'
                }
            },

            paginarResultados () {
                const consultaAlmacen = (this.consulta.almacenID === 'Seleccionar') ? this.user.almacenID : this.consulta.almacenID
                const consultaUsuario = (this.consulta.usuario === 'Seleccionar') ? '' : this.consulta.usuario

				this.consulta.almacenID = (this.$can('correccion.consultar.ajuste.inventario.salida.por.sucursal')) ? consultaAlmacen : this.user.almacenID
				this.consulta.usuario = (this.$can('correccion.consultar.ajuste.inventario.salida.por.usuario')) ? consultaUsuario : this.user.usuario

                this.getPaginate({ ...this.paginate, ...this.consulta })
                .then(response => {
                    this.changePaginate(response.data.meta)
                })
                .catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            },

            imprimirTermico (ajusteID) {
                utils.openRecibo(`/reporte/ajuste/termica/${ajusteID}`)
            },

            reporteCarta (ajusteID) {
                utils.openRecibo(`/reporte/ajuste/reporte/carta/${ajusteID}`)
            }
        },

        mounted () {
            this.getAlmacenes()
            this.paginarResultados()
            this.getUsuarioListar()
        }
    }
</script>